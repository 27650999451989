import React from "react";
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import CriticalHead from "../components/CriticalHead"
import FirstScreen from "../components/for-mansion-objects/FirstScreen"
import PriceSection from "../components/for-mansion-objects/priceSection"
import ObjectDetails from "../components/for-mansion-objects/ObjectDetails"
import CTAbutton from "../components/ObjectsCtaButton"
// import TellUsMore from "../components/for-mansion-objects/TellUsMore.js"
import KoshevickiyIntroduction from "../components/KoshevickiyIntroduction.js";
import ToMainBottomLink from "../components/for-mansion-objects/toMainBottomLink.js"
import GetDPFbutton from "../components/for-mansion-objects/GetPDFbutton"
import ObjectsFormModal from "../components/forms/ObjectsFormModal"
import ObjectsPresentationModal from "../components/forms/ObjectsPresentationModal"

export const query = graphql`
    query LandObjectsQuery($id: String!) {
        land: sanityLand (id: { eq: $id }) {
            id
            objectID
            slug {
                current
            }
            orderPriority
            name
            metaDescription
            priceOnDemand
            currencyOfSell
            priceToSell
            imgFront {
                _key
                ...ImageWithPreview
                asset {
                    metadata {
                        dimensions { 
                            aspectRatio
                            height
                            width
                        }
                    }
                    url
                }
            }
            presetation {
                asset {
                    size
                    url
                }
            }
            siteArea
            siteBuildings
            siteProperty
            siteStatus
            siteType
            siteResources
            }
        }
    `

const LandObjectTemplate = ({ data }) => {
    const name = data.land.name;
    const description = data.land.metaDescription;
    const presentation = data.land.presetation;
    const {priceOnDemand, currencyOfSell, priceToSell} = data.land;
    const {
        objectID, siteArea, siteBuildings, siteProperty,
        siteStatus, siteType, siteResources} = data.land;
    
    const { url } = data.land.imgFront.asset
    const imageRepresentativeURL = `${url}/?fit=crop&w=1200&h=900&v=2021-11-18`

    // const quote = <TellUsMore />
    const quote =
        <p className="quote">
            Предложения на сайте всегда актуальные, но не исчерпывающие.
            Если вы не нашли что искали, - свяжитесь с нами, возможно, сможем помочь.
        </p>;

    return (
        <Layout>
            <CriticalHead title={name} description={description} coverLogo={imageRepresentativeURL} />
            <ObjectsFormModal idOfInterest={objectID} />
            {
                presentation !== null &&
                <ObjectsPresentationModal idOfInterest={objectID} name={name} file={presentation.asset} />
            }
            <article className="landObject">
                <FirstScreen name={name} image={data.land.imgFront} />
                <section className={`land objectDescription`}>
                    <PriceSection 
                        priceOnDemand={priceOnDemand}
                        sellPossible={true}
                        rentPossible={false}
                        rentOccupied={undefined}
                        currencyOfSell={currencyOfSell}
                        currencyOfRent={undefined}
                        priceToSell={priceToSell}
                        priceToRent={undefined}
                    />
                    <ObjectDetails 
                        objectID={objectID}
                        siteArea={siteArea}
                        siteBuildings={siteBuildings}
                        siteProperty={siteProperty}
                        siteStatus={siteStatus}
                        siteType={siteType}
                        siteResources={siteResources}
                        houseStatus={null}
                    />
                    <div className="presentation-container">
                        {presentation !== null ? <GetDPFbutton CTA={"Презентация"} /> : null}
                    </div>
                        <CTAbutton CTA="Заявка на просмотр" /> 
                </section>
                <div className="divider-ornament" />
                <KoshevickiyIntroduction quote={quote} objectID={objectID} />
                <ToMainBottomLink />
            </article>
        </Layout>
    );
}

export default LandObjectTemplate;